import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { spacing } from "src/ui/spacing"

export const PillsWrapper = styled.div<{ $noWrap?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: ${(props) => (props.$noWrap ? "nowrap" : "wrap")};
`

export const FilterWrapper = styled.div`
  display: inline-flex;
  padding-top: ${spacing.S};
  gap: 1rem;
`

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const CenterText = styled.div`
  align-items: center;
`
// ts-prune-ignore-next
export const Ellipsis = styled.div`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const LoadingWrapper = styled.div`
  padding-bottom: ${spacing.L};
  padding-top: ${spacing.M};
  padding-inline: ${spacing.M};
`

export const ActionsWrapper = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: start;
  flex-direction: column;
  flex-wrap: wrap;

  @media (${breakpoint.mediumUp}) {
    align-items: center;
    flex-direction: row;
  }
`

export const ParadiseLayout = styled.div`
  padding-inline: ${spacing.L};
  padding-block: ${spacing.S};
`

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.XS2};
`

export const ExpandableWrapper = styled.div`
  padding-block: ${spacing.M};
`

export const DescriptionWrapper = styled.div`
  display: flex;
  gap: ${spacing.XS2};
`

export const IdWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
  justify-content: space-between;
`

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
